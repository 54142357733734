import React from 'react'
import AppContext from 'components/AppContext'
import { companyService } from 'value-studio-sdk'



export default class AppCompanies extends React.Component {
  static contextType = AppContext

  constructor(props, context) {
    super(props, context)
    
    this.getCompaniesByTicker = this.getCompaniesByTicker.bind(this)
    this.prevContext = context
  }

  componentDidMount() {
    if (!this.context.user) return
    this.getCompaniesByTicker()
  }

  componentDidUpdate() {
    if (!this.prevContext.user && this.context.user) this.getCompaniesByTicker()
    this.prevContext = this.context
  }

  getCompaniesByTicker() {
    let companiesByTicker = sessionStorage.getItem('companiesByTicker')

    if (companiesByTicker == null) {
      companyService.allCompanies({ compactResults: true }).then(results => {
        let companiesByTicker = results.reduce((acc, company) => {
          let {
            marketcap,
            profitmargin,
            totalrevenue,
            netincometocommon,
            pricetoearnings
          } = company

          if (!profitmargin && (totalrevenue && netincometocommon)) {
            company.profitmargin = netincometocommon > 0 && totalrevenue > 0 ? (
              parseInt(netincometocommon) / parseInt(totalrevenue)
            ) : 0
          }

          if (!pricetoearnings && (marketcap && netincometocommon)) {
            company.pricetoearnings = netincometocommon > 0 && marketcap > 0 ? (
              parseInt(marketcap) / parseInt(netincometocommon)
            ) : 0
          }

          acc[company.ticker] = company
          return acc
        }, {})

        this.context.setState({ companiesByTicker })
        sessionStorage.setItem(
          'companiesByTicker',
          JSON.stringify(companiesByTicker)
        )
      })
    } else {
      this.context.setState({
        companiesByTicker: JSON.parse(companiesByTicker)
      })
    }
  }

  render() {
    return null
  }
}
