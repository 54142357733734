import React from 'react'
import AppContext from 'components/AppContext'
import { listService } from 'value-studio-sdk'



export default class AppLists extends React.Component {
  static contextType = AppContext

  constructor(props, context) {
    super(props, context)
    
    this.getLists = this.getLists.bind(this)

    this.context.refreshLists = this.getLists

    this.prevContext = context
  }

  componentDidMount() {
    if (!this.context.user) return
    this.getLists()
  }

  componentDidUpdate() {
    if (!this.prevContext.user && this.context.user) this.getLists()
    this.prevContext = this.context
  }

  getLists() {
    Promise.all([
      listService.getLists(),
      listService.getListItems()
    ]).then(([lists, listItems]) => {
      this.context.setState({
        lists,
        listItems
      })
    })
  }

  render() {
    return null
  }
}
