import { useState } from 'react'
import '../scss/main.scss'
import Head from 'next/head'
import getConfig from 'next/config'
import NextNProgress from 'nextjs-progressbar'
import AppContext from 'components/AppContext'
import PageError from 'components/PageError'
import Layout from 'components/Layout'
import {models} from 'value-studio-sdk'
import * as Sentry from '@sentry/browser'
import AppSics from 'components/AppSics'
import AppCompanies from 'components/AppCompanies'
import AppLists from 'components/AppLists'

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig()

if (publicRuntimeConfig.NODE_ENV == 'production') {
  Sentry.init({
    dsn: 'https://1256593c0c3c45d69f92d201f9435027@o406335.ingest.sentry.io/5275570'
  })
}

export default class ValueInvestorStudio extends React.Component {
  constructor(props) {
    super(props)

    this.userStateUpdate = this.userStateUpdate.bind(this)

    this.state = {
      user: this.userStateUpdate()
    }

    this.state.setState = this.setState.bind(this)
  }

  userStateUpdate() {
    let {pageProps} = this.props
    let user = pageProps.user ? pageProps.user instanceof models.User ? pageProps.user : new models.User(pageProps.user) : undefined
    return user
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.pageProps.user && this.props.pageProps.user) this.setState({
      user: this.userStateUpdate()
    })

    if (prevProps.pageProps.user && !this.props.pageProps.user) this.setState({
      user: this.userStateUpdate()
    })
  }

  componentDidCatch(error, errorInfo) {
    if (publicRuntimeConfig.NODE_ENV == 'production') {
      Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });

        Sentry.captureException(error);
      });
    }

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    let {pageProps, Component} = this.props

    let component = pageProps.error ? (
      <PageError responseCode={pageProps.error} />
    ) : (
      <Component {...pageProps} />
    )

    let appComponentsRequiringAuthorization = pageProps.user ? (
      <>
      <AppCompanies />
      <AppLists />
      </>
    ) : null

    return (
      <>
        <Head>
          <meta key="viewport" name="viewport" content="width=device-width, user-scalable=no"/>
        </Head>
        <NextNProgress color="var(--primary)" options={{
          showSpinner: false
        }}/>
        <AppContext.Provider value={this.state}>
          <AppSics />
          { appComponentsRequiringAuthorization }
          { component }
        </AppContext.Provider>
      </>
    )
  }
}
