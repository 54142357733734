import {
  Container,
  Row,
  Col
} from 'react-bootstrap'
import Layout from 'components/Layout'


export default function PageError({responseCode, message}) {
  let errorName = {
    '404': 'Not Found'
  }[responseCode]

  return (
    <Layout hideSearch>
      <Container>
        <Row className='my-5'>
          <Col sm='12' className='py-3 text-center'>
            <h1 className="text-muted">
              {errorName}
            </h1>
            <p className='text-muted'>
              {message}
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
