import React from 'react'
import AppContext from 'components/AppContext'
import { companyService } from 'value-studio-sdk'
import fetch from 'isomorphic-unfetch'



function flatDeep(arr, d = Infinity) {
  return d > 0 ? arr.reduce((
    acc,
    val
  ) => acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val), []) : arr.slice();
}


export default class AppSics extends React.Component {
  static contextType = AppContext

  constructor(props, context) {
    super(props, context)

    this.getSicData = this.getSicData.bind(this)
  }

  componentDidMount() {
    this.getSicData()
  }

  getSicData() {
    let sicDictionary = sessionStorage.getItem('sicDictionary')
    let sicsByCode = sessionStorage.getItem('sicsByCode')

    if ([sicsByCode, sicDictionary].indexOf(null) > -1) {
      fetch('/sic/sic_divisions.json').then( r => r.json() ).then( data => {

        let divisions = data
        let sics = flatDeep(Object.keys(divisions).map(divKey => {
          let division = divisions[divKey]
          return Object.keys(division.majorGroups).map(majGrKey => {
            let majorGroup = division.majorGroups[majGrKey]
            return Object.keys(majorGroup.industryGroups).map(indGrKey => {
              let industryGroup = majorGroup.industryGroups[indGrKey]
              return Object.keys(industryGroup.sics).map(sicKey => {
                let sic = industryGroup.sics[sicKey]
                return {
                  code: sicKey,
                  indGrKey,
                  majGrKey,
                  divKey,
                  ...sic
                }
              })
            })
          })
        }))

        let sicsByCode = sics.reduce((acc, cur) => {
          acc[cur.code] = cur
          return acc
        }, {})

        let majGroupSics = {
          ...sics.reduce((acc, cur) => {
            acc[cur.majGrKey +'00'] = {
              code: cur.majGrKey +'00',
              divKey: cur.divKey,
              majGrKey: cur.majGrKey,
              label: divisions[cur.divKey].majorGroups[cur.majGrKey].majorGroup,
              description: divisions[cur.divKey].majorGroups[cur.majGrKey].description
            }

            return acc
          }, {})
        }

        let indGrSics = {
          ...sics.reduce((acc, cur) => {
            acc[cur.indGrKey +'0'] = {
              divKey: cur.divKey,
              majGrKey: cur.majGrKey,
              indGrKey: cur.indGrKey,
              label: divisions[cur.divKey].majorGroups[cur.majGrKey].industryGroups[cur.indGrKey].industryGroup,
            }

            return acc
          }, {})
        }

        sicsByCode = {
          ...sicsByCode,
          ...majGroupSics,
          ...indGrSics,
        }

        this.context.setState({ sicsByCode, sicDictionary: divisions })
        sessionStorage.setItem('sicsByCode', JSON.stringify(sicsByCode))
        sessionStorage.setItem('sicDictionary', JSON.stringify(divisions))
      })
    } else {
      this.context.setState({
        sicsByCode: JSON.parse(sicsByCode),
        sicDictionary: JSON.parse(sicDictionary)
      })
    }
  }

  render() {
    return null
  }
}

